<div nz-row nzJustify="space-around">
    <div nz-col [nzXs]="{ span: 6.3, offset: 0 }" [nzLg]="{ span: 6, offset: 0 }" >   
        <img
            nz-image
            style="margin-top: 20px;"
            width="250px"
            height="100px"
            nzSrc='https://www.cloverfileve.com/wp-content/uploads/2022/04/1502852123.png'
            alt=""
        />
        <div class="viewer" nz-col [nzXs]="{ span: 24, offset: 3 }" [nzLg]="{ span: 24, offset: 2 }" style="margin-top: 110px;">
            <p  nz-row   style="font-family: Calibri, sans-serif;font-size: 2.4em;  display: contents;">
                <a style="font-size: 22px; color:rgb(133, 137, 134); font-family: Montserrat;">Bienvenido al </a> <b style="font-size: 30px; color: rgb(14, 65, 126);font-family: Montserrat;">SISTEMA DE PRÉSTAMO DOCUMENTAL de CloverFile</b> 

            </p>
        </div>
    </div>
    

    <div style="margin-left: -123px;" class="viewer" nz-row nzJustify="space-around" nzAlign="middle" >
        <div nz-col [nzXs]="{ span: 0, offset: 1 }" [nzLg]="{ span: 6, offset: 0 }" style="padding-right: 10px;">
        <img 
        class="imageMenu"
            width="450px"
            height="200PX"
            nz-image 
            nzSrc='../../assets/Baner prestamos documetal.jpg'
            alt=""
        />
        </div>


    </div>
    <div nz-col [nzXs]="{ span: 14, offset: 0 }" [nzLg]="{ span: 6, offset:1  }">
        <div class="btn-wrappers"
        nz-flex
        [nzJustify]="justifySegment[2]"
        [nzAlign]="alignSegment[1]">
        
        
            <form  nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
                
        
                <nz-form-item>
                <nz-form-control nzErrorTip="Por favor ingresa tu nombre de usuario!">
                    <nz-input-group nzPrefixIcon="user">
                    <input type="text" nz-input formControlName="userName" placeholder="Usuario" />
                    </nz-input-group>
                </nz-form-control>
                </nz-form-item> 
                <nz-form-item>
                <nz-form-control nzErrorTip="Por favor ingresa tu contraseña!">
                    <nz-input-group nzPrefixIcon="lock">
                    <input type="password" nz-input formControlName="password" placeholder="Contraseña" />
                    </nz-input-group>
                </nz-form-control>
                </nz-form-item>
                <div nz-row class="login-form-margin">
                <div nz-col [nzSpan]="12">
            
                </div>
                <div nz-col [nzSpan]="12">
                    <a class="login-form-forgot" (click)="showModal()">¿Olvidaste tu contraseña?</a>
                    
                </div>
                </div>
                <button nz-button class="login-form-button login-form-margin" [nzLoading]="isLoadingOne" [nzType]="'primary'">Iniciar Sesión</button>
        
            </form>
        
        </div>
        
    
    </div>
</div>
<nz-modal
      [(nzVisible)]="isVisible"
      [nzTitle]="modalTitle"
      [nzContent]="modalContent"
      [nzFooter]="modalFooter"
      (nzOnCancel)="handleCancel()"
    >
    <div class="size_modal">
      <ng-template #modalTitle>Recuperar Contraseña</ng-template>

      <ng-template #modalContent>
        <form  nz-form [formGroup]="validateFormPass" (ngSubmit)="submitFormPass()">
                
        
            <nz-form-item>
            <nz-form-control nzErrorTip="Por favor introduzca el nombre de usuario">
                <nz-input-group nzPrefixIcon="user">
                    <input [(ngModel)]="holaa" (ngModelChange)="onOptionsSelected($event)" type="text" nz-input formControlName="userName" placeholder="Usuario" />
                </nz-input-group>
            </nz-form-control>
            </nz-form-item> 
            <nz-form-item>
                <nz-form-control [nzSpan]="0" nzErrorTip="Por favor seleccione el Correo">
                  <div class="Selected_cajas">
                    <label for="correos"><b>Seleccione el Correo</b></label>
                    <nz-input-group class="correo" nzPrefixIcon="mail">

                    <input [(ngModel)]="this.hola" type="text" nz-input class="correo" formControlName="correos" placeholder="Correo" />
                </nz-input-group>
                  </div>
                </nz-form-control>
              </nz-form-item>
            <div nz-row class="login-form-margin">
            <div nz-col [nzSpan]="12">
        
            </div>
            <div nz-col [nzSpan]="12">
                <button nz-button class="login-form-button login-form-margin" [nzLoading]="isConfirmLoading" [nzType]="'primary'">Enviar</button>
            </div>
            </div>
            
    
        </form>
      </ng-template>

      <ng-template #modalFooter>
      </ng-template>
    </div>
    </nz-modal>




  <router-outlet name="chatbot"></router-outlet>
  <router-outlet name="footer"></router-outlet>
  <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
  <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>
