import { Component, OnInit, ViewChild } from '@angular/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormControl, FormsModule,FormGroup, NonNullableFormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import {NzGridModule } from 'ng-zorro-antd/grid';
import { NzFlexModule,NzAlign, NzJustify } from 'ng-zorro-antd/flex';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SocketService } from '../services/socket.service';
import { HttpClient } from '@angular/common/http';
import { Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ResetComponent } from '../reset/reset.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { AdminService } from '../services/admin.service';
import { NzSelectModule } from 'ng-zorro-antd/select';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    NzSelectModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    ReactiveFormsModule,
    FormsModule,
    NzCheckboxModule,
    NzGridModule,
    NzFlexModule,
    NzIconModule,
    RouterOutlet,
    CommonModule,
    ResetComponent,
    NzImageModule,
    NzDividerModule,
    NzModalModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})



export class LoginComponent implements OnInit {
  login: any=true;
  isLoadingOne = false;
  isVisible = false;
  isConfirmLoading = false;
  hola:any;
  holaa:any;
  emails: any=[];
  Businnes:any=[];
  correoxd:any;
  constructor(
    private fb: NonNullableFormBuilder,
    private adminservice:AdminService,
    private ServiceLogin:SocketService,
    private http:HttpClient,
    private router:Router,
    private notification: NzNotificationService,
    private cookieService: CookieService  
    ) {}
  postId: any;

  ngOnInit() {}


  public justifySegment: NzJustify[] = [
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly'
  ];
  public alignSegment: NzAlign[] = ['flex-start', 'center', 'flex-end'];  
 
  validateForm: FormGroup<{
    userName: FormControl<string>;
    password: FormControl<string>;
    remember: FormControl<boolean>;
  }> = this.fb.group({
    userName: ['', [Validators.required]],
    password: ['', [Validators.required]],
    remember: [true]
  });

  validateFormPass: FormGroup<{
    userName: FormControl<string>;
    correos: FormControl<string>;
  }> = this.fb.group({
    userName: ['', [Validators.required]],
    correos: [{value:'',disabled:true} ,[Validators.required]]
  });

  handleOk(): void {
    this.isConfirmLoading = true;
    setTimeout(() => {
      this.isVisible = false;
      this.isConfirmLoading = false;
    }, 1000);
  }
  submitForm(): void {
    if (this.validateForm.valid) {
      const userName = this.validateForm.value.userName ?? '';
this.cookieService.set('Usercap', userName);
      this.ServiceLogin.LoginPost(this.validateForm.value).subscribe((res:any)=>{
      
      if(res.status===3){
        this.router.navigate(["/reset"])
        this.cookieService.set('TypeUser', res.status);
        
        this.login=false
        this.createNotification('success',res.message,'Cambio de Contraseña')
      }     
      else if(res.status===4){
        this.router.navigate(["/admin"])
        this.cookieService.set('TypeUser', res.status);
        this.cookieService.set('Token', res.token);

      }
      else if(res.status===1){
      this.router.navigate(["/menu"])
      this.cookieService.set('TypeUser', res.status);
      this.cookieService.set('Token', res.token);
  

    }
      else this.createNotification('error',res.message,'Inicio de sesión')
   });
 
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  submitFormPass(): void {
    if (this.validateFormPass.valid) {


      this.adminservice.SendEmailPass(this.validateFormPass.value.userName, this.correoxd).subscribe((res:any)=>{
      
     if(res.status===1){
      this.createNotification('success',res.message,'Olvido de contraseña')
        this.handleOk()
    }
      else this.createNotification('error',res.message,'Inicio de sesión')
   });
 
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  createNotification(type: string,Value:string,Titulo:string): void {
    
    this.notification.create(
      type,
      Titulo,
      Value
    );
  }

  loadOne(): void {
    this.isLoadingOne = true;
    setTimeout(() => {
      this.isLoadingOne = false;
    }, 999);
  }
  showModal(): void {
    this.isVisible = true;
  }

 

  handleCancel(): void {
    this.isVisible = false;
  }
 
  onOptionsSelected(j:any){
    
    this.hola = '';
    this.adminservice.GetEmails(j).subscribe(res=>{
      let partes = res.CORREO.split('@');
      let nombre = partes[0];
      let dominio = partes[1];
      let inicio = nombre.charAt(0);
      let fin = nombre.charAt(nombre.length - 1);
    
      let nombreCodificado = inicio + '*'.repeat(nombre.length - 2) + fin;
      this.correoxd = res.CORREO;
      this.hola = nombreCodificado + '@' + dominio;
     
    });
  }
}


