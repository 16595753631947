import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NzSelectModule, NzSelectSizeType } from 'ng-zorro-antd/select';
import { BehaviorSubject, Observable, Observer, catchError, debounceTime, map, of, switchMap } from 'rxjs';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { FormControl, FormGroup, FormsModule, NonNullableFormBuilder, Validators,ReactiveFormsModule, AsyncValidatorFn, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { DemoNgZorroAntdModule } from '../../ngzorro-antd.module';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { SocketService } from '../../services/socket.service';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-user',
  standalone: true,
  imports: [
    NzSelectModule,
    CommonModule,
    NzDatePickerModule,
    DemoNgZorroAntdModule,
    NzFlexModule,
    FormsModule,
    NzFormModule,
    ReactiveFormsModule,
    NzInputNumberModule ,
    RouterLink,
    RouterOutlet
  ],
  templateUrl: './user.component.html',
  styleUrl: './user.component.css'
})
export class UserComponent implements OnInit{
  Businnes: any=[];
  emails: any=[];
  selectedValue = null;
  selectedValue2 = null;
  CorreoElement: any=0 ;
  emailsAutors: any=[];



  constructor(
    private fb: NonNullableFormBuilder,
    private adminservice:AdminService,
    private notification: NzNotificationService,
    private Service: SocketService,
    private router:Router,
    private cookieService: CookieService
    ) {

    this.validateForm = this.fb.group({
      userName: ['', [Validators.required], [this.userNameAsyncValidator]],
      email: ['', [Validators.required]],
      Autorizado: ['', [Validators.required]],
      correo: ['', [Validators.required]], 
      password: ['', [Validators.required]],
      confirm: ['', [this.confirmValidator]]

    });
  }

  createNotification(type: string,Value:string,Titulo:string): void {
    
    this.notification.create(
      type,
      Titulo,
      Value
    );
  }

  validateForm: FormGroup<{
    userName: FormControl<string>;
    email: FormControl<string>;
    correo: FormControl<string>;
    Autorizado: FormControl<string>;
    password: FormControl<string>;
    confirm: FormControl<string>;  
  }>;


  listOfOption: Array<{ label: string; value: string }> = [];
  size: NzSelectSizeType = 'default';
  singleValue = '';
  multipleValue = [''];
  tagValue = [''];

  ngOnInit(): void {
    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
    }
    this.listOfOption = children;

    this.adminservice.GetBusiness().subscribe(res=>{
  
      this.Businnes = res
    });

  }
  onIncreaseCounter(){
    this.Service.logout().subscribe(res=>{
      this.cookieService.delete('Token');
      this.router.navigate(["rrt"])
    });
  }

  submitForm(): void {


    this.adminservice.Getidentifier(this.validateForm.value).subscribe((res:any)=>{
    if(res.status===1){
    this.createNotification('success',res.message,'')
    this.validateForm.reset();
  } else 
    this.createNotification('error',res.message,'')
  });
  this.adminservice.SendEmail(this.validateForm.value).subscribe((res:any)=>{;
    if(res.status===1){
    this.createNotification('success',res.message,'')
    this.validateForm.reset();
  } else 
    this.createNotification('error',res.message,'')
  });
  }
  
  onOptionsSelected(j:any,k:any){
    
    this.emails= k[3].split(",");
    this.emailsAutors=k[4].split(",");
  }

  onOptionsposition(j:any,k:any){
 
    this.CorreoElement = this.emails.indexOf(k)
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
  }

  validateConfirmPassword(): void {
    setTimeout(() => this.validateForm.controls.confirm.updateValueAndValidity());
  }

  userNameAsyncValidator: AsyncValidatorFn = (control: AbstractControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        if (control.value === 'Admin') {
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          observer.next(null);
        }
        observer.complete();
      }, 1000);
    });

  confirmValidator: ValidatorFn = (control: AbstractControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };




}
