import { ApplicationConfig, importProvidersFrom, NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { provideRouter, RouterLink, RouterOutlet } from '@angular/router';
import { HashLocationStrategy,LocationStrategy } from '@angular/common';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { es_ES, provideNzI18n } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';


import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { DemoNgZorroAntdModule } from './ngzorro-antd.module';
import { MenuComponent } from './menu/menu.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { AppComponent } from './app.component';
import { PrestamoComponent } from './menu/Pages/solicitudes/prestamo/prestamo.component';
import { LoginComponent } from './Login/login.component';
import { ResetComponent } from './reset/reset.component';




registerLocaleData(es);

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideClientHydration(), provideNzI18n(es_ES), importProvidersFrom(FormsModule), provideAnimationsAsync(), provideHttpClient()]
};

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])


@NgModule({
  declarations: [],
  imports: [
    BrowserModule,FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ScrollingModule,
    DragDropModule,
    DemoNgZorroAntdModule,
    MenuComponent,
    NzButtonModule,
    AppComponent,
    RouterOutlet,
    RouterLink,
    PrestamoComponent,
    LoginComponent,
    ResetComponent
  ],
  exports : [ MenuComponent,AppComponent,LoginComponent,ResetComponent,RouterOutlet ] ,
  bootstrap: [ ],
  providers: [ { provide: NZ_I18N, useValue: en_US }, { provide: NZ_ICONS, useValue: icons },{provide:LocationStrategy, useClass:HashLocationStrategy} ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
