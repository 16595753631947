<nz-header>
  <a onclick="history.back()" class="logo"></a>
 
  <div class="logout_butom" (click)="onIncreaseCounter()"><a *ngIf="'this.view===true'" (click)="onIncreaseCounter()" class="outtext">Salir</a><div (click)="onIncreaseCounter()" class="icon_out">&nbsp;</div></div>
  
</nz-header>
<nz-divider class="title_table" nzText="Logs de Auditoría"></nz-divider>
<nz-table class="table_log" [nzPageSize]="5" [nzFrontPagination]=true #basicTable [nzData]="ServicioRRT.LogsBitacora">
    <thead>
      <tr>
        <th class="backg">FECHA DEL EVENTO</th>
        <th class="backg">EVENTO</th>
        <th class="backg">USUARIO</th>
        <th class="backg">DIRECCIÓN IP</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td>{{ data.Fecha_hora }}</td>
        <td>{{ data.Evento }}</td>
        <td>{{ data.Usuario }}</td>
        <td>{{ data.Direccion_ip }}</td>
      </tr>
    </tbody>
  
  </nz-table>

  <router-outlet name="footer"></router-outlet>