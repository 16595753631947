import { Component, OnInit, inject } from '@angular/core';
import { NzTableFilterFn, NzTableFilterList, NzTableModule, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { SharepointService } from '../../services/sharepoint.service';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SocketService } from '../../services/socket.service';
import { CookieService } from 'ngx-cookie-service';

interface DataItem {
  Direccion_ip: string;
  Question: string;
  Fecha_hora: any;
  Usuario: string 
}

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<DataItem> | null;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn<DataItem> | null;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: 'app-LCB',
  standalone: true,
  imports: [NzTableModule,NzPaginationModule,NzDividerModule,CommonModule,ReactiveFormsModule,NzLayoutModule,RouterLink,NzIconModule,RouterOutlet],
  templateUrl: './logs-chat-bot.component.html',
  styleUrl: './logs-chat-bot.component.css'
})


export class LogsChatBotComponent implements OnInit {

  ngOnInit(): void {
    

  }
  constructor(    
    public Service: SocketService,
    private router:Router,
    private cookieService: CookieService)  {}

  public ServicioRRT = inject(SharepointService)

  listOfColumns: ColumnItem[] = [
    {
      name: 'Direccion_ip',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a.Direccion_ip.localeCompare(b.Direccion_ip),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [
        { text: 'Joe', value: 'Joe' },
        { text: 'Jim', value: 'Jim', byDefault: true }
      ],
      filterFn: (list: string[], item: DataItem) => list.some(Direccion_ip => item.Direccion_ip.indexOf(Direccion_ip) !== -1)
    },
    {
      name: 'Preguntas',
      sortOrder: 'descend',
      sortFn: (a: DataItem, b: DataItem) => a.Question.length - b.Question.length,
      sortDirections: ['descend', null],
      listOfFilter: [],
      filterFn: null,
      filterMultiple: true
    },
    {
      name: 'Fecha_hora',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: DataItem, b: DataItem) => a.Fecha_hora.length - b.Fecha_hora.length,
      filterMultiple: false,
      listOfFilter: [
        { text: 'London', value: 'London' },
        { text: 'Sidney', value: 'Sidney' }
      ],
      filterFn: (Fecha_hora: string, item: DataItem) => item.Fecha_hora.indexOf(Fecha_hora) !== -1
    },
    {
      name: 'Usuario',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a.Direccion_ip.localeCompare(b.Usuario),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [
        { text: 'Joe', value: 'Joe' },
        { text: 'Jim', value: 'Jim', byDefault: true }
      ],
      filterFn: (list: string[], item: DataItem) => list.some(Usuario => item.Usuario.indexOf(Usuario) !== -1)
    }

  ];

  listOfData: DataItem[] = [
    this.ServicioRRT.LogsBitacoraCB
  ];
  onIncreaseCounter(){
    this.Service.logout().subscribe(res=>{
      this.cookieService.delete('Token');
      this.router.navigate(["rrt"])
    });
  }

}