<nz-header>
  <a onclick="history.back()" class="logo"></a>
 
  <div class="logout_butom" (click)="onIncreaseCounter()"><a *ngIf="'this.view===true'" (click)="onIncreaseCounter()" class="outtext">Salir</a><div (click)="onIncreaseCounter()" class="icon_out">&nbsp;</div></div>
  
</nz-header>
<nz-divider class="title_table" nzText="Creación de Usuario"></nz-divider>
<div nz-flex
[nzJustify]="'center'"
[nzAlign]="'center'"
class="btn-wrappers" >

<form nz-form  style="margin-top: 100px;width: 60%" [formGroup]="validateForm" (ngSubmit)="submitForm()">
    <nz-divider class="cabecera" nzText="Crear Usuario"></nz-divider>
    <nz-form-item>
        <nz-form-control [nzSpan]="0" nzErrorTip="Por favor seleccione la Razón Social">
          <div class="Selected_cajas">
  <label for="cajas"><b>Seleccione la Razón Social</b></label>
            <nz-select [(ngModel)]="selectedValue" (ngModelChange)='onOptionsSelected($event,selectedValue)' formControlName="email" nzShowSearch nzAllowClear nzPlaceHolder="Seleccione la Razón Social">
                <nz-option *ngFor="let item of Businnes" [nzLabel]="item.Razon_s" [nzValue]="[item.Razon_s,item.SAP_code,item.ID_NUBE,item.Persons_Contact,item.CORREO]"></nz-option>
            </nz-select>
</div>
        </nz-form-control>
    </nz-form-item> 
    <nz-form-item>
      <nz-form-control [nzSpan]="0" nzErrorTip="Por favor seleccione la persona autorizada">
        <div class="Selected_cajas">
          <label for="Autorizado"><b>Persona Autorizada</b></label>
          <nz-select formControlName="Autorizado" [(ngModel)]="selectedValue2" (ngModelChange)='onOptionsposition($event,selectedValue2)' nzShowSearch nzAllowClear nzPlaceHolder="Persona Autorizada">
              <nz-option *ngFor="let emailItem of  this.emails"  [nzLabel]="emailItem" [nzValue]="emailItem"></nz-option>
          </nz-select>
        </div>
      </nz-form-control>
    </nz-form-item>
    
    <nz-form-item>
      <nz-form-control [nzSpan]="0" nzErrorTip="Correo asociado a persona autorizada">
        <div class="Selected_cajas">
          <label for="correo"><b>Correo asociado a persona autorizada</b></label>
          <input nz-input formControlName="correo" placeholder="Correo" [ngModel]="this.emailsAutors[this.CorreoElement]" [disabled]="true" />
        </div>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item> 
      <nz-form-control [nzSpan]="0" nzHasFeedback nzValidatingTip="Validating..." [nzErrorTip]="userErrorTpl">
        <div class="Selected_cajas">
          <label for="cajas"><b>Nombre de usuario</b></label>
        <input [disabled]="true" nzRequired nz-input formControlName="userName" placeholder="Nombre de usuario" />
        <ng-template #userErrorTpl let-control>
          @if (control.errors?.['required']) {
           Por favor ingrese el nombre de usuario
          }
          @if (control.errors?.['duplicated']) {
            Nombre de Usuario existente
          }
        </ng-template>
        </div>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
  
      <nz-form-control [nzSpan]="0" nzHasFeedback nzErrorTip="Por favor ingrese la contraseña">
        <div class="Selected_cajas">
          <label for="cajas"><b>Contraseña</b></label>
        <input nz-input type="password" formControlName="password" placeholder="Contraseña" (ngModelChange)="validateConfirmPassword()" />
      </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzSpan]="0" nzHasFeedback [nzErrorTip]="passwordErrorTpl">
        <div class="Selected_cajas">
          <label for="cajas"><b>Confirma tu contraseña</b></label>
        <input nz-input type="password" formControlName="confirm" placeholder="Confirma tu contraseña" />
        <ng-template #passwordErrorTpl let-control>
          @if (control.errors?.['required']) {
           Por favor confirme la contraseña
          }
          @if (control.errors?.['confirm']) {
           Contraseña inconsistente
          }
        </ng-template>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-flex
    [nzJustify]="'center'">
      <nz-form-control nz-flex
      [nzJustify]="'center'" [nzOffset]="7" [nzSpan]="12">
        <button nz-button nzType="primary" [disabled]="!validateForm.valid">Guardar</button>
        <button nz-button (click)="resetForm($event)">Resetear</button>
      </nz-form-control>
    </nz-form-item>
  </form>

</div>

<router-outlet name="footer"></router-outlet>