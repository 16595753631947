import { Routes } from '@angular/router';
import { LoginComponent } from './Login/login.component';
import { ResetComponent } from './reset/reset.component';
import { AuthGuard } from './auth/auth.guard';
import { MenuComponent } from './menu/menu.component';
import {PrestamoComponent} from './menu/Pages/solicitudes/prestamo/prestamo.component';
import { UserComponent } from './admin/User/user.component';
import { AdminComponent } from './admin/admin.component';
import { AccesscontrolComponent } from './admin/accesscontrol/accesscontrol.component';
import { AuditComponent } from './admin/audit/audit.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { Component } from '@angular/core';
import { FooterComponent } from './footer/footer.component';
import { CajasComponent } from './menu/Pages/solicitudes/cajas/cajas.component';
import { LogsChatBotComponent } from './admin/logs-chat-bot/logs-chat-bot.component';
import { AuthGuard2 } from './auth/router-admin.guard';
import { VentasComponent } from './ventas/ventas.component';
import { OperacionesComponent } from './operaciones/operaciones.component';
import { DashboardComponent } from './dashboard/dashboard.component';

 
export const routes: Routes = [

      { path: '', 
      component:DashboardComponent,
      children: [
        { path: '', component: ChatbotComponent, outlet:'chatbot'},
        { path: '', component: FooterComponent, outlet:'footer'}
      ]},

      { path: 'rrt', 
      component:LoginComponent,
      children: [
        { path: '', component: ChatbotComponent, outlet:'chatbot'},
        { path: '', component: FooterComponent, outlet:'footer'}
      ]},
      
      { path: 'ventas', 
      component:VentasComponent,
      children: [
        { path: '', component: FooterComponent, outlet:'footer'}
      ]},
      { path: 'operaciones', 
      component:OperacionesComponent,
      children: [
        { path: '', component: FooterComponent, outlet:'footer'}
      ]},
      { path: 'menu', 
      component:MenuComponent,canActivate:[AuthGuard],
      children: [
        { path: '', component: ChatbotComponent},
        { path: '', component: FooterComponent, outlet:'footer'}
      ]},
      { path: 'prestamo', component:PrestamoComponent,
        children:[
          { path: '', component: ChatbotComponent},
        { path: '', component: FooterComponent, outlet:'footer'}
        ]
      },
      {
        path:'reset', component: ResetComponent,
        children:[
          { path: '', component: ChatbotComponent},
          { path: '', component: FooterComponent, outlet:'footer'}
        ] 
      },
      { 
        path: 'solicitudes',loadChildren: () => import('./menu/Pages/solicitudes/solicitudes-routing.module').then(m => m.WELCOME_ROUTES),canActivate:[AuthGuard]
      },
      { 
        path: 'ventas',loadChildren: () => import('./ventas/ventas-routing.module').then(m => m.WELCOME_ROUTES)
      },
      { 
        path: 'solicitudes', component:PrestamoComponent,children:[
          { path: '', component: FooterComponent, outlet:'footer'}
          ] 
      },
      {
        path:'admin', component: AdminComponent,canActivate:[AuthGuard,AuthGuard2],
        children:[
        { path: '', component: FooterComponent, outlet:'footer'}
        ]
      },
      {
        path:'user', component: UserComponent,canActivate:[AuthGuard,AuthGuard2],
        children:[
        { path: '', component: FooterComponent, outlet:'footer'}
        ]
      },
      {
        path:'accesos', component: AccesscontrolComponent,canActivate:[AuthGuard,AuthGuard2],
        children:[
        { path: '', component: FooterComponent, outlet:'footer'}
        ]
      },
     
      {
        path:'auditoria', component: AuditComponent,canActivate:[AuthGuard,AuthGuard2],
        children:[
        { path: '', component: FooterComponent, outlet:'footer'}
        ]
      },
      {
        path:'LogsCB', component: LogsChatBotComponent,canActivate:[AuthGuard,AuthGuard2],
        children:[
          { path: '', component: ChatbotComponent},
        { path: '', component: FooterComponent, outlet:'footer'}
        ]
      }

];


  