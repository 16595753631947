

    <nz-layout>

      <nz-layout class="inner-layout">
        <nz-content><router-outlet></router-outlet></nz-content>
      </nz-layout>
    </nz-layout>






