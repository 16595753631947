<nz-header>
  <div class="logo"></div>
 
  <div class="logout_butom" (click)="onIncreaseCounter()"><a *ngIf="'this.view===true'" (click)="onIncreaseCounter()" class="outtext">Salir</a><div (click)="onIncreaseCounter()" class="icon_out">&nbsp;</div></div>
  
</nz-header>
  
  <div class="fath"
  nz-row       
    nz-flex
    [nzJustify]="'space-around'" 
  >

    <div class="textMain" nz-col [nzXs]="{ span: 10, offset: 1 }" [nzLg]="{ span: 6, offset: 1 }">
    
      <a style="font-size: 28px; color:rgb(133, 137, 134); font-family: Montserrat !important;">Bienvenido al </a> <b style="font-size: 30px; color: rgb(14, 65, 126);font-family: Montserrat;">SISTEMA DE PRÉSTAMO DOCUMENTAL de CloverFile</b> 
    </div>

    <div class="divsito" nz-col [nzXs]="{ span: 6, offset: 1 }" [nzLg]="{ span: 4, offset: 1 }">
      <button class="img-content img_bckg_cu" nz-button routerLink="user" (click)="Solicitar($event,'user')" [nzSize]="'large'" nzType="primary">&nbsp;</button>
      
      <span class="label_button">Creación de Usuarios</span>
    </div>
    <div class="divsito" nz-col [nzXs]="{ span:6, offset: 1 }" [nzLg]="{ span: 4, offset: 1 }">
      <button class="img-content img_bckg_cc" nz-button routerLink="accesos" (click)="Solicitar($event,'accesos')" [nzSize]="'large'" nzType="primary">&nbsp;</button>
      
      <span class="label_button">Control de Accesos</span>
    </div>
    <div class="divsito" nz-col [nzXs]="{ span: 6, offset: 1 }" [nzLg]="{ span: 4, offset: 1 }">
        <button class="img-content img_bckg_aud" nz-button routerLink="auditoria" (click)="Solicitar($event,'auditoria')" [nzSize]="'large'" nzType="primary">&nbsp;</button>
        
        <span class="label_button">Logs de Auditoría</span>
    </div>

    <div class="divsito" nz-col [nzXs]="{ span: 6, offset: 1 }" [nzLg]="{ span: 4, offset: 1 }">
      <button class="img-content img_bckg_cb" nz-button routerLink="LogsCB" (click)="Solicitar($event,'LogsCB')" [nzSize]="'large'" nzType="primary">&nbsp;</button>
      
      <span class="label_button">Logs del ChatBot</span>
    </div>
  
  </div>
  
  

  <router-outlet name="footer"></router-outlet>