<nz-header>
  <div class="logo"></div>
 
  <div class="logout_butom" (click)="onIncreaseCounter()"><a *ngIf="'this.view===true'" (click)="onIncreaseCounter()" class="outtext">Salir</a><div (click)="onIncreaseCounter()" class="icon_out">&nbsp;</div></div>
  
</nz-header>

<div class="aling"
nz-row       
  nz-flex
  [nzJustify]="'center'"
>


  <div class="text_menu" nz-col [nzXs]="{ span: 5, offset: 1 }" [nzLg]="{ span: 6, offset: 1 }">
    <p class="text_welcome">Bienvenidos al<b style="font-size: 30px; color: rgb(14, 65, 126);font-family: Montserrat;"> Sistema de Préstamo Documental</b>, que CloverFile pone a tu disposición</p>
  </div>
  
  <div class="center_button" nz-col [nzXs]="{ span: 11, offset: 1 }" [nzLg]="{ span: 6, offset: 1 }">
    <button class="botton-content botton_bckg_request" nz-button routerLink="solicitudes/prestamo" (click)="Solicitar($event,'prestamo')" [nzSize]="'large'" nzType="primary">&nbsp;</button>
    <span class="label_button">Nueva solicitud</span>
  </div>
  <div class="center_button" nz-col [nzXs]="{ span: 5, offset: 1 }" [nzLg]="{ span: 6, offset: 1 }">
    <button class="botton-content botton_bckg_bitacora"  nz-button routerLink="solicitudes/cajas" (click)="Solicitar($event,'cajas')" [nzSize]="'large'" nzType="primary">&nbsp;</button>
    <span class="label_button">Bitácora de solicitudes</span>
  </div>

</div>




<router-outlet></router-outlet>
<router-outlet name="footer"></router-outlet>