<nz-header>
  <a onclick="history.back()" class="logo"></a>
 
  <div class="logout_butom" (click)="onIncreaseCounter()"><a *ngIf="'this.view===true'" (click)="onIncreaseCounter()" class="outtext">Salir</a><div (click)="onIncreaseCounter()" class="icon_out">&nbsp;</div></div>
  
</nz-header>
<nz-divider class="title_table" nzText="Control de Accesos"></nz-divider>
  <nz-table class="table_access" [nzPageSize]="5" [nzFrontPagination]=true #basicTable [nzData]="this.NewRegister">
      <thead>
        <tr>
          <th nzCustomFilter  *ngFor="let column of listOfColumns" [nzSortFn]="column.compare" class="backg"> {{ column.title }}
            <nz-filter-trigger *ngIf="column.title === 'NOMBRE DE USUARIO'" [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu">
              <span nz-icon nzType="search"></span>
            </nz-filter-trigger>
          </th>
          <th class="backg"
            *ngFor="let column of listOfColum"
            [nzFilterMultiple]="column.filterMultiple"
            [nzFilters]="column.listOfFilter"
            [nzFilterFn]="column.filterFn"
          >
            {{ column.title }}
          </th>
          <th class="backg">ACCIÓN</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{data.Username}}</td>
          <td>{{data.Razon_social}}</td>
          <td>{{showString(data.sesion)}}</td>
          <td>
  <button *ngIf="data.sesion !== 3" (click)="UpdateUserRegister($event, data.ID, 3)">
    <span nz-icon nzType="delete" nzTheme="outline"></span>
  </button>
  <button *ngIf="data.sesion !== 0" (click)="UpdateUserRegister($event, data.ID, 0)">
    <span nz-icon nzType="retweet" nzTheme="outline"></span>
  </button>
  <button *ngIf="data.sesion !== 2" (click)="UpdateUserRegister($event, data.ID, 2)">
    <span nz-icon nzType="lock" nzTheme="outline"></span>
  </button>
  <button *ngIf="data.sesion !== 1" (click)="UpdateUserRegister($event, data.ID, 1)">
    <span nz-icon nzType="unlock" nzTheme="outline"></span>
  </button>
</td>
        </tr>
      </tbody>
    
    </nz-table>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Buscar Username" [(ngModel)]="searchValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">Buscar</button>
          <button nz-button nzSize="small" (click)="reset()">Resetear</button>
        </div>
      </div>
    </nz-dropdown-menu>

    <router-outlet name="footer"></router-outlet>