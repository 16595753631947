import { Component, OnInit } from '@angular/core';
import {NzTableModule, NzTableFilterFn} from 'ng-zorro-antd/table';
import { SharepointService } from '../../services/sharepoint.service';
import {
  NzTableSortFn,
  NzTableSortOrder,NzTableFilterList
} from "ng-zorro-antd/table";
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AdminService } from '../../services/admin.service';
import { SocketService } from '../../services/socket.service';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { CookieService } from 'ngx-cookie-service';
interface ColumnItem{
  sortOrder: NzTableSortOrder | null;
  listOfFilter: NzTableFilterList;
  sortFn: NzTableSortFn<DataItem> | null;
  filterFn: NzTableFilterFn<DataItem> | null;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
};
interface DataItem {
  Username: string;
  Razon_social:string;
  sesion:number;
  ID:number;
};
@Component({ 
  selector: 'app-accesscontrol',
  standalone: true,
  imports: [
    NzDropDownModule,
    NzInputModule,
    NzTableModule,
    NzPaginationModule,
    NzDividerModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NzLayoutModule,
    RouterLink,
    NzIconModule,
    NzFormModule,
    NzButtonModule,
    NzCheckboxModule,
    RouterOutlet
  ],

  templateUrl: './accesscontrol.component.html',
  styleUrl: './accesscontrol.component.css'
})
export class AccesscontrolComponent implements OnInit{
  Register: any=[];
  NewRegister: any=[];
  searchValue:any = '';
  visible = false;
  menu: any;
 estadoFilterOptions = [
    { text: 'A espera de inicio de sesión', value: 0 },
    { text: 'Activo', value: 1 },
    { text: 'Bloqueado', value: 2 },
    { text: 'Desactivado', value: 3 },
  ];
  
  listOfColumns= [
    {
      title: "NOMBRE DE USUARIO",
      sortDirections: ["ascend", "descend", null],
      compare: (a: DataItem, b: DataItem) => a.Username.localeCompare(b.Username)
    },
    {
      title: "RAZÓN SOCIAL",
      sortDirections: ["ascend", "descend", null],
      compare: (a: DataItem, b: DataItem) => a.Razon_social.localeCompare(b.Razon_social)
    },
  ];
  listOfColum= [
  {
    title: 'ESTADO',
    filterMultiple: true,
    listOfFilter: this.estadoFilterOptions,
    filterFn: (list: number[], item: DataItem) => list.includes(item.sesion)
  }
];
  constructor(private ServicesAdmin: AdminService,
     private Service: SocketService,
    private router:Router,
    private cookieService: CookieService)  {}

  ngOnInit(): void {
    
   this.ServicesAdmin.GetUserRegister().subscribe(res=>{this.Register= res; this.NewRegister=[...this.Register];})
  }

  reset(): void {
    this.searchValue = '';
    this.search();
  }

  search(): void {
    this.visible = false;
    this.NewRegister = this.Register.filter((item: any) =>
      item.Username.toLowerCase().startsWith(this.searchValue.toLowerCase()));

  }
  showIcon(event:any) {
    if (event === 0) {
        return 'check-square';
    } else if (event === 1) {
        return 'check-circle';
    } else if(event ===2){
        return 'warning';
    }else { return 'minus-circle';}
 }

 showString(event:any){
  if (event === 0) {
    return 'A espera de inicio de sesión';
} else if (event === 1) {
    return 'Activo';
} else if(event ===2){
    return 'Bloqueado';
}else { return 'Desactivado';}
 }

 UpdateUserRegister(Value:any,ID:any,Estado:any){

  this.ServicesAdmin.SetUser(ID,Estado).subscribe(res=>{


    this.ServicesAdmin.GetUserRegister().subscribe(res=>{this.Register= res; this.NewRegister=[...this.Register];})
  });
  

 }


 

 showEditAction(Value:any){

if(Value===2){
  return true}
  else return false

 }
 onIncreaseCounter(){
  this.Service.logout().subscribe(res=>{
    this.cookieService.delete('Token');
    this.router.navigate(["rrt"])
  });
}

}
