<div nz-row nzJustify="space-around">
    <div nz-col [nzXs]="{ span: 6.3, offset: 0 }" [nzLg]="{ span: 4, offset: 0 }" >   
        <img
            nz-image
            style="margin-top: 20px;"
            width="250px"
            height="100px"
            nzSrc='https://www.cloverfileve.com/wp-content/uploads/2022/04/1502852123.png'
            alt=""
        />

    </div>
    
</div>

<br><br>
<div
class="aling"

    >
    <div class="center_button"   style="width: 100%;margin-right: 1%;" >
        <button style="margin-right: 1%;width:70%;font-size: large;background: #004387;line-height:0px" nz-button routerLink="rrt" nzType="primary">Clientes</button><br>
        <img  routerLink="rrt" class="bckg_icon_email" style="width:70%;">
    </div>
    <div class="center_button"  style="width:100%;margin-right: 1%;">
        <button style="margin-right: 1%;width:70%;font-size: large;background: #004387;line-height:0px" nz-button routerLink="ventas" nzType="primary">Ventas</button><br>
        <img routerLink="ventas" class="bckg_icon_image" style="width:70%;">
        
    </div>

    <div class="center_button"  style="width:100%;margin-right: 1%;">
        <button  style="width:70%;font-size: large;background: #004387;line-height:0px"nz-button routerLink="operaciones" nzType="primary">Operaciones</button><br>
        <img routerLink="operaciones" class="bckg_icon_email2" style="width:70%;">
    </div>
</div>

<br><br><br><br><br>
  
  <router-outlet name="footer"></router-outlet>
  <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
  <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>

