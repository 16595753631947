import { Component } from '@angular/core';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SocketService } from '../services/socket.service';
import { ActivatedRoute, Params,RouterLinkActive } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [

  NzSwitchModule,
  NzDividerModule,
  NzMenuModule,
  NzIconModule,
  NzToolTipModule,
  NzLayoutModule,
  NzBreadCrumbModule,
  NzImageModule,
  NzGridModule,
  NzSpaceModule,
  NzCardModule,
  NzButtonModule,
  RouterLink,
  RouterOutlet,
  CommonModule,
  RouterLinkActive
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent {
  constructor(private router:Router,private Service: SocketService,private cookieService: CookieService) { }
  
  Solicitar(event:any,prueba:any){
    this.router.navigateByUrl(`${prueba}`); 

  }

  onIncreaseCounter(){
    this.Service.logout().subscribe(res=>{
      this.cookieService.delete('Token');
      this.router.navigate(["rrt"])
    });
  }
}
