import { Injectable } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoggedIn = false;
  isLoggedIn2 = false;
  constructor(private cookieService: CookieService ) { }
  
isAuthenticated(){
 

  if(this.cookieService.get('Token')==="")
  { 
    return this.isLoggedIn;
  } else 
  return !this.isLoggedIn;
    
  }

  TypeUserAuthenticated(){

    if(this.cookieService.get('TypeUser')!=="4")
      { 
        return this.isLoggedIn2;
      } else
      return !this.isLoggedIn2;
    
  }

}