<div nz-row nzJustify="space-around">
    <div nz-col [nzSpan]="18" [nzPush]="1">   
        <img nz-col nzFlex="3"
            nz-image
            style="margin-top: 70px;"
            width="290px"
            height="120px"
            nzSrc='https://www.cloverfileve.com/wp-content/uploads/2022/04/1502852123.png'
            alt=""
        />
    </div>
</div>
        <div nz-row>
            <div nz-col nzFlex="2" [nzPush]="6"><b style="font-size: 30px;
                color: #ffffff;
                background-color: rgb(0 67 135);
                padding: 10px;
                text-align: center;
                display: flex;">Estamos innovando para ofrecer nuevas soluciones</b></div>
            <div nz-col nzFlex="2" [nzPull]="18"></div>
            
        </div>
   
        <div nz-row>
            <div nz-col nzFlex="1" [nzPush]="2"></div>
            <div nz-col  nzFlex="8" [nzPush]="4">
                
                <b style="font-size: 20px; 
                    color: #8bb019;
                    text-align: right;
                    display: flex;
                    align-items: right;
                    align-content: center;
                    margin-right: 45%;">
                    ¡Próximamente publicaremos nuevas funcionalidades para usted y su empresa!</b>
            </div>
            <div nz-col nzFlex="1" [nzPush]="2"></div>
            
        </div>

    
    





  <router-outlet name="chatbot"></router-outlet>
  <router-outlet name="footer"></router-outlet>
  <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
  <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>